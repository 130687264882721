* {margin: 0rem;  padding: 0rem;}
html, body {width: 100%; height: 100%;font-size:62.5%;overflow: hidden;background-color:#F5F5F5;font-family: "PingFang SC", "Helvetica Neue", "Hiragino Sans GB", "Segoe UI", "Microsoft YaHei", "微软雅黑", sans-serif;  }
.body{ width: 100%;height: 100%;display: flex;flex-direction: column}
.body .header{ display: flex;width: 100%;height: 5rem;align-items: center;padding-left:0.2rem;background-color: #ffffff }
.body .main{ display: flex;flex: 1;background: url('../img/cs-background5-1.png') no-repeat center;background-size: 100% 100%}
.main .main-background{ display: flex;flex: 1; background-color: rgba(0, 0, 0, 0.5);padding: 3rem 2rem;align-items: center}
.footer{ width: 100%;height: 3rem;line-height:3rem;font-size:1.2rem;color:#666;text-align: center}

.login,
.register{ width: 100%;height:auto;background-color: #ffffff;border-radius: 0.8rem}
/*.login h2{font-size: 2rem;  font-weight: 400;  text-align: center;  color: #323a45}*/
/*.register{ width: 100%;height:auto;background-color: #ffffff;border-radius: 0.8rem}*/
.login h2,
.register h2{margin:0rem 0rem 0.5rem 0rem; font-size: 2rem;  font-weight: 400;  text-align: center;  color: #323a45}

.main .register form .red-border,
.main .login form .red-border {
    border-color: red;
}
small,
small span{font-size: 1.2rem;color: #666666}
small a{font-size: 1.2rem}